// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_success__uK6v9{
    background: #05824a;
}

.style_error__m9AgF{
    background: #f12929;
}

#style_toast__HxNwT{
    transition: all 1000ms;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 75px;
    bottom: 0;

    padding-top: 5px;
    padding-left: 15px;
    color: #f1f1f1;
}

.style_hide__jfXTc{
    display: none;
}

.style_show__aDAUZ{
    display: flex;
}

#style_component__2K9w3{
    width: 90%;
    display: flex;
    flex-direction: column;
}

#style_title__zk\\+d4{
    font-size: 1.4em;
}

#style_message__fEfvV{
    padding-top: 2px;
    font-size: 1.1em;
}

#style_closeBtn__aAW4h{
    font-size: 1.2em;
    display: flex;
    justify-content: flex-end;
    width: 9%;
    font-weight: 800;
}

#style_closeBtn__aAW4h:hover{
    cursor: pointer;
}

.style_loading__JGqZJ{
    background: #757575;
}`, "",{"version":3,"sources":["webpack://./src/App/Component/Toast/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,SAAS;;IAET,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".success{\n    background: #05824a;\n}\n\n.error{\n    background: #f12929;\n}\n\n#toast{\n    transition: all 1000ms;\n    position: fixed;\n    z-index: 1000;\n    width: 100%;\n    height: 75px;\n    bottom: 0;\n\n    padding-top: 5px;\n    padding-left: 15px;\n    color: #f1f1f1;\n}\n\n.hide{\n    display: none;\n}\n\n.show{\n    display: flex;\n}\n\n#component{\n    width: 90%;\n    display: flex;\n    flex-direction: column;\n}\n\n#title{\n    font-size: 1.4em;\n}\n\n#message{\n    padding-top: 2px;\n    font-size: 1.1em;\n}\n\n#closeBtn{\n    font-size: 1.2em;\n    display: flex;\n    justify-content: flex-end;\n    width: 9%;\n    font-weight: 800;\n}\n\n#closeBtn:hover{\n    cursor: pointer;\n}\n\n.loading{\n    background: #757575;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `style_success__uK6v9`,
	"error": `style_error__m9AgF`,
	"toast": `style_toast__HxNwT`,
	"hide": `style_hide__jfXTc`,
	"show": `style_show__aDAUZ`,
	"component": `style_component__2K9w3`,
	"title": `style_title__zk+d4`,
	"message": `style_message__fEfvV`,
	"closeBtn": `style_closeBtn__aAW4h`,
	"loading": `style_loading__JGqZJ`
};
export default ___CSS_LOADER_EXPORT___;
