import './style.css';

const Footer = ()=>{
	return(
		<div id={'footer-container'}>
			<div id={'question-container'}>
				Have Any Questions? Let’s Get in Touch!
			</div>

			<div id={'company-location-container'}>
				<div id={'company-location-component'}>
					<div id={'company-location-title'}>
						<img id={'logo-sm'} src={'assets/logo_white.png'}/>
						PT. Packing Material Indonesia
					</div>
					<div className={'company-location-office'}>
						<b>Jakarta Office:</b>
						<br/>
						Jl. Letjen Suprapto Blok A/31 Komplek Ruko Mega Grosir
						<br/>
						Cempaka Mas, Kel. Sumur Batu, Kec. Kemayoran,
						<br/>
						Jakarta Pusat 10640, Indonesia.
						<br/>
						Telp: +62(21)42805661
					</div>
					<div className={'company-location-office'}>
						<b>
							Banjarmasin Office:
						</b>
						<br/>
						Jl. Pramuka Komp. Tirta Darma, Palm View Residence
						<br/>
						No. D3, Banjarmasin 70249, Indonesia.
						<br/>
						Telp: +62(511)3255778
						<br/>
						Fax: 3251413
					</div>
				</div>
			</div>

		</div>
	);
}

export default Footer;