// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #012D26;
}

.title{
    font-size: 2.8em;
    font-weight: bold;
}

.sub-title{
    font-size: 1.075em;
    color: #E65924;
    font-weight: bold;
}

@media screen and (max-width: 1100px){
    .title{
        font-size: 1.5em;
        text-align: center;
    }
    .sub-title{
        font-size: 0.85em;
        text-align: center;
    }
}

@media screen and (max-width: 500px){
    .title{
        font-size: 1.2em;
    }
    .sub-title{
        font-size: 0.85em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/Component/Title/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI;QACI,gBAAgB;QAChB,kBAAkB;IACtB;IACA;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".title-container{\r\n    width: 100%;\r\n    height: 70px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    color: #012D26;\r\n}\r\n\r\n.title{\r\n    font-size: 2.8em;\r\n    font-weight: bold;\r\n}\r\n\r\n.sub-title{\r\n    font-size: 1.075em;\r\n    color: #E65924;\r\n    font-weight: bold;\r\n}\r\n\r\n@media screen and (max-width: 1100px){\r\n    .title{\r\n        font-size: 1.5em;\r\n        text-align: center;\r\n    }\r\n    .sub-title{\r\n        font-size: 0.85em;\r\n        text-align: center;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 500px){\r\n    .title{\r\n        font-size: 1.2em;\r\n    }\r\n    .sub-title{\r\n        font-size: 0.85em;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
